import React, { useEffect , useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Card, CardBody, CardTitle, Col, Container, FormGroup, Form, Input, Label, Nav, NavItem, NavLink, Row } from "reactstrap";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { HTTP_STATUS } from "../../../../constants";
import { attributeApi } from "../../../../apis";
import { convertImagePath } from '../../../../helpers/utils';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import Paginations from "components/Common/Pagination";
import Spinners from "components/Common/Spinner";

const AttributeType = () => {

  //meta title
  document.title = "Products | Sprezzy - React Admin & Dashboard Template";

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const type = location.pathname.split("/attribute-types/").pop();

  const [productsList, setProductsList] = useState([]);
  const [productsPerPage, setProductPerPage] = useState([]);
  const [cateList, setCateList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [selectedCate, setSelectedCate] = useState(state?.cate || null);
  const [isLoading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [selectedChildCate, setSelectedChildCate] = useState('');
  const [openChild, setOpenChild] = useState(false);

  useEffect(() => {
    if(cateList?.length) {
      setSelectedCate(cateList[0]);
      handleGetProductsCate(cateList[0]);
    }
  }, [cateList?.length, type]);

  useEffect(() => {
    if (!isEmpty(productsList)) setProductsList(productsList);
  }, [productsList]);

  useEffect(() => {    
    if(searchKey !== "") {
      handleGetProductsCate(selectedCate, searchKey);
    } else {
      if(selectedCate) {
        handleGetProductsCate(selectedCate);
      }
    }
  }, [searchKey]);

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const perPageData = 9;
  const indexOfLast = currentPage * perPageData;
  const indexOfFirst = indexOfLast - perPageData;

  useEffect(() => {
    if(productsList) {
      setProductPerPage(productsList.slice(indexOfFirst, indexOfLast));
    }
  }, [currentPage, productsList?.length, type]);
  // search
  const handleSearch = (ele) => {
    const query = ele.value.toLowerCase();
    setSearchKey(query);
  }

  useEffect(() => {
    async function fetchCates() {
      try {
        const { data, status } = await attributeApi.getAttributeType();
        if (status === HTTP_STATUS.OK) {
          setCateList(data?.filter(item => item?.valueType?.toLowerCase()?.includes(type)) || []);
        }
      } catch (error) {
        console.log('get attribute error: ', error);
      }
    }
    fetchCates();
  }, [type]);

  const handleGetProductsCate = async (cloth, keySearch = undefined) => {
    try {
      const { data, status } = await attributeApi.getAttributeTypeId(cloth?.id);
      if (status === HTTP_STATUS.OK) {
        if(keySearch) {
          const values = data?.attributesConfigs?.filter((product) => product?.name?.toLowerCase().includes(keySearch?.toLowerCase()));
          setProductsList(values);
        } else {
          const check = data?.attributesConfigs?.filter(item => item?.childAttributeConfig?.length === 0);
          console.log('data: ', data);
          console.log('check: ', check);
          if(check.length === data?.attributesConfigs.length) {
            setProductsList(data?.attributesConfigs);
            setOpenChild(false);
          } else {
            setProductsList(data?.attributesConfigs?.filter(item => item?.childAttributeConfig?.length > 0));
            setOpenChild(true);
          }
        }
      }
    } catch (error) {
      console.log('get attribute error: ', error);
    }
  };

  const handleDeleteProduct = async (item) => {
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Attribute type- " breadcrumbItem="Jacket" />
          <Row>
            <Col lg="3">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Filter</CardTitle>
                    <div>
                      <h5 className="font-size-14 mb-3">{selectedCate?.name || 'Fabric Category'}</h5>
                      {/* Render Cloth Categories */}
                      <ul className="list-unstyled product-list">
                        {(cateList || [])?.map((cloth, key) => (
                          <li key={"_li_" + key}>
                            <Link onClick={() => {
                              setSelectedCate(cloth)
                              handleGetProductsCate(cloth);
                              setSearchKey("");
                            }}>
                              <i className="mdi mdi-chevron-right" /> {cloth.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={9}>
              <Row className="mb-3">
                <Col xl={4} sm={6}>
                  <div className="mt-2" style={{ textAlign: 'left' }}>
                    <h5>{selectedChildCate ? `${selectedCate?.name} - ${selectedChildCate}` : selectedCate?.name}</h5>
                  </div>
                </Col>
                <Col lg={8} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Form className="mt-4 mt-sm-0 float-sm-end d-sm-flex align-items-center">
                    <div className="search-box me-2">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control border-0"
                          placeholder="Search..."
                          onChange={(e) => handleSearch(e.target)}
                          value={searchKey}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                    <Nav className="product-view-nav justify-content-end mt-3 mt-sm-0" pills>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggleTab("1");
                          }}
                        >
                          <i className="bx bx-grid-alt" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggleTab("2");
                          }}
                        >
                          <i className="bx bx-list-ul" />
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Form>
                </Col>
              </Row>
              {
                isLoading ? <Spinners setLoading={setLoading} />
                  :
                  <>
                    <Row>
                      {!isEmpty(productsPerPage) &&
                        (productsPerPage || [])?.map((product, key) => {
                          return (
                            <Col xl={4} sm={6} key={"_col_" + key}>
                              <Card>
                                <CardBody>
                                  <div className="product-img position-relative">
                                  {
                                    !openChild ? (
                                      <Link
                                            to={`/attribute-types/jacket/${product?.id}/attribute-configs`}
                                            className="text-dark"
                                          >
                                        <img
                                          style={{ height: "auto"  }}
                                          src={convertImagePath(product?.images)}
                                          alt=""
                                          className="img-fluid mx-auto d-block"
                                        />
                                      </Link>
                                    ) : (
                                      selectedChildCate !== '' ? (
                                        <Link
                                          to={`/attribute-types/jacket/${product?.id}/attribute-configs`}
                                          className="text-dark"
                                        >
                                          <img
                                            style={{ height: "auto"  }}
                                            src={convertImagePath(product?.images)}
                                            alt=""
                                            className="img-fluid mx-auto d-block"
                                          />
                                        </Link>
                                      ) : (
                                        <Link
                                          className="text-dark"
                                          onClick={() => {
                                            setProductsList(product?.childAttributeConfig);
                                            setSelectedChildCate(product.name)
                                          }}>
                                          <img
                                            style={{ height: "auto"  }}
                                            src={convertImagePath(product?.images)}
                                            alt=""
                                            className="img-fluid mx-auto d-block"
                                          />
                                        </Link>
                                      )
                                    )
                                  }
                                  </div>
                                  <div className="mt-4 text-center">
                                    <h5 className="mb-2 text-truncate">
                                      {product.name}
                                    </h5>
                                    <h5 className="mb-1 text-truncate" style={{ fontSize: '12px' }}>
                                      {product.type}
                                    </h5>
                                    <p className="mb-1 text-truncate" style={{ fontSize: '12px' }}>
                                      {product.description}
                                    </p>
                                    {
                                      product.price > 0 && (
                                        <h5 className="my-0">
                                          <b>${product.price}</b>
                                        </h5>
                                      )
                                    }
                                    <div style={{
                                      marginTop: '10px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      gap: '10px',
                                      cursor: 'pointer'
                                    }}>
                                      <i className="fas fa-trash" onClick={
                                        () => {
                                          if (window.confirm("Do you really want to delete product?")) {
                                            handleDeleteProduct(product)
                                          }
                                        }
                                      }></i>
                                      <i className="fas fa-edit" onClick={() => {
                                        navigate(`/ecommerce-add-product`, {
                                          state: { item: product }
                                        })
                                      }}></i>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          )
                        })}
                    </Row>
                    <Paginations
                      perPageData={perPageData}
                      data={productsList}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      isShowingPageLength={false}
                      paginationDiv="col-lg-12"
                      paginationClass="pagination pagination-rounded justify-content-center mt-3 mb-4 pb-1"
                    />
                  </>
              }

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AttributeType);
