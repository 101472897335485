import * as rest from "./base";

const getCate = () => rest.get("/categories");
const getCateDetail = () => rest.get(`/categories/${id}`);
const getProductCate = (id, page, limit) =>
    rest.get(`/categories/${id}/products?page=${page}&limit=${limit}`);
const postProductCate = (id, body) =>
    rest.post(`/categories/${id}/products`, body);
const deleteProductCate = (id, productId) => rest.del(`/categories/${id}/products/${productId}`);

export default {
    getCate,
    getCateDetail,
    getProductCate,
    postProductCate,
    deleteProductCate
};
  