import * as rest from "./base";

const getProductCate = (id, page, limit) =>
  rest.get(`/categories/${id}/products?page=${page}&limit=${limit}`);
const getProductDetail = (id) => rest.get(`/products/${id}`);
const postProduct = (body) => rest.post(`/products`, body);
const getProducts = () => rest.get(`/products`);
const postProductImage = (id, body) => rest.post(`/products/${id}/photos`, body);
const deleteProduct = (id) => rest.del(`/products/${id}`);
const deleteProductImage = (id, photoId) => rest.del(`/products/${id}/photos/${photoId}`);
const patchProduct = (id, body) => rest.patch(`/products/${id}`, body);

export default {
  getProductCate,
  getProductDetail,
  postProduct,
  getProducts,
  postProductImage,
  deleteProduct,
  deleteProductImage,
  patchProduct
};
