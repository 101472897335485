/* eslint-disable react/prop-types */
import React, {createContext, useReducer} from 'react';

export const SpreadContext = createContext();

const initialState = {
  isLoading: false,
};

const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_IS_LOADING':
            return {...state, isLoading: action.payload};
        default:
            return state;
    }
};

const SpreadProvider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);

    function setIsLoading(data) {
      dispatch({ type: 'SET_IS_LOADING', payload: data });
    }

    const actions = {
      setIsLoading
    };

    return (
      <SpreadContext.Provider value={{ state, dispatch, actions }}>
          {children}
      </SpreadContext.Provider>
    );
};



export default SpreadProvider;
