import React, { useEffect , useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Card, CardBody, CardTitle, Col, Container, FormGroup, Form, Input, Label, Nav, NavItem, NavLink, Row } from "reactstrap";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { HTTP_STATUS } from "../../../../constants";
import { productApi, cateApi, fabricApi } from "../../../../apis";
import { convertImagePath } from '../../../../helpers/utils';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import Paginations from "components/Common/Pagination";
import Spinners from "components/Common/Spinner";

const CustomFabric = () => {

  //meta title
  document.title = "Products | Sprezzy - React Admin & Dashboard Template";

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [productLists, setProductLists] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [productsPerPage, setProductPerPage] = useState([]);
  const [cateList, setCateList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [selectedCate, setSelectedCate] = useState(state?.cate || null);
  const [isLoading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    if(cateList?.length) {
      setSelectedCate(cateList[0]);
    }
  }, [cateList?.length]);

  useEffect(() => {
    if (!isEmpty(productsList)) setProductsList(productsList);
  }, [productsList]);

  useEffect(() => {    
    if(searchKey !== "") {
      handleGetProductsCate(selectedCate, searchKey);
    } else {
      if(selectedCate) {
        handleGetProductsCate(selectedCate);
      } else {
        setProductsList(productLists);
      }
    }
  }, [searchKey]);

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const perPageData = 30;
  const indexOfLast = currentPage * perPageData;
  const indexOfFirst = indexOfLast - perPageData;

  useEffect(() => {
    if(productsList) {
      setProductPerPage(productsList.slice(indexOfFirst, indexOfLast));
    }
  }, [currentPage, productsList?.length]);
  // search
  const handleSearch = (ele) => {
    const query = ele.value.toLowerCase();
    setSearchKey(query);
  }

  useEffect(() => {
    async function fetchCates() {
      try {
        const { data, status } = await fabricApi.getFabricType();        
        if (status === HTTP_STATUS.OK) {
          setCateList(data);
        }
      } catch (error) {
        console.log('get attribute error: ', error);
      }
    }
    fetchCates();
  }, []);

  useEffect(() => {
    setProductsList(productLists);
  }, [productLists?.length]);

  async function fetchProds() {
    try {
      const { data, status } = await fabricApi.getFabric();        
      if (status === HTTP_STATUS.OK) {
        setProductLists(data);
      }
    } catch (error) {
      console.log('get attribute error: ', error);
    }
  }

  useEffect(() => {
    fetchProds();
  }, []);

  const handleGetProductsCate = async (cloth, keySearch = undefined) => {
    try {
      const { data, status } = await fabricApi.getFabricTypeFabrics(cloth?.id);        
      if (status === HTTP_STATUS.OK) {
        if(keySearch) {
          const values = data?.fabrics?.filter((product) => product?.name?.toLowerCase().includes(keySearch?.toLowerCase()));
          setProductsList(values);
        } else {
          setProductsList(data?.fabrics);
        }
      }
    } catch (error) {
      console.log('get attribute error: ', error);
    }
  };

  const handleDeleteProduct = async (item) => {
    try {
      await cateApi.deleteProductCate(selectedCate?.id, item?.id);
      for (let i = 0; i < item?.photos; i++) {
        await productApi.deleteProductImage(item?.id, item?.photos?.[index]);
      }
      const { status } = await productApi.deleteProduct(item.id);      
      if(status === HTTP_STATUS.OK) {
        const newProducts = productsList.filter(product => product.id !== item.id);
        setProductsList(newProducts);
        window.alert("Delete product successfully!");
      } else {
        window.alert("Delete product failed!");
      }
    } catch (error) {
      window.alert("Delete product failed!");
      console.log('delete product error: ', error);
    }
  };
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Custom suit- " breadcrumbItem="Fabrics" />
          <Row>
            <Col lg="3">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Filter</CardTitle>
                  <div>
                    <h5 className="font-size-14 mb-3">{selectedCate?.name || 'Fabric Category'}</h5>
                    {/* Render Cloth Categories */}
                    <ul className="list-unstyled product-list">
                      {(cateList || [])?.map((cloth, key) => (
                        <li key={"_li_" + key}>
                          <Link onClick={() => {
                            setSelectedCate(cloth)
                            handleGetProductsCate(cloth);
                            setSearchKey('');
                          }}>
                            <i className="mdi mdi-chevron-right" /> {cloth.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={9}>
              <Row className="mb-3">
                <Col xl={4} sm={6}>
                  <div className="mt-2" style={{ textAlign: 'left' }}>
                    <h5>{selectedCate?.name || 'Fabric'}</h5>
                  </div>
                </Col>
                <Col lg={8} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Form className="mt-4 mt-sm-0 float-sm-end d-sm-flex align-items-center">
                    <div className="search-box me-2">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control border-0"
                          placeholder="Search..."
                          onChange={(e) => handleSearch(e.target)}
                          value={searchKey}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                    <Nav className="product-view-nav justify-content-end mt-3 mt-sm-0" pills>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggleTab("1");
                          }}
                        >
                          <i className="bx bx-grid-alt" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggleTab("2");
                          }}
                        >
                          <i className="bx bx-list-ul" />
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Form>
                </Col>
              </Row>
              {
                isLoading ? <Spinners setLoading={setLoading} />
                  :
                  <>
                    <Row>
                      {!isEmpty(productsPerPage) &&
                        (productsPerPage || [])?.map((product, key) => {
                          return (
                            <Col xl={4} sm={6} key={"_col_" + key}>
                              <Card>
                                <CardBody>
                                  <div className="product-img position-relative">
                                    <Link
                                      to={"/"}
                                      className="text-dark"
                                      >
                                      <img
                                        style={{ height: "auto"  }}
                                        src={convertImagePath(product?.image)}
                                        alt=""
                                        className="img-fluid mx-auto d-block"
                                      />
                                    </Link>
                                  </div>
                                  <div className="mt-4 text-center">
                                    <h5 className="mb-3 text-truncate">
                                      {product.name}
                                    </h5>
                                    <h5 className="my-0">
                                      <b>${product.price}</b>
                                    </h5>
                                    <div style={{
                                      marginTop: '10px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      gap: '10px',
                                      cursor: 'pointer'
                                    }}>
                                      <i className="fas fa-trash" onClick={
                                        () => {
                                          if (window.confirm("Do you really want to delete product?")) {
                                            handleDeleteProduct(product)
                                          }
                                        }
                                      }></i>
                                      <i className="fas fa-edit" onClick={() => {
                                        navigate(`/custom-suit/add-fabric`, {
                                          state: { item: product }
                                        })
                                      }}></i>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          )
                        })}
                    </Row>
                    <Paginations
                      perPageData={perPageData}
                      data={productsList}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      isShowingPageLength={false}
                      paginationDiv="col-lg-12"
                      paginationClass="pagination pagination-rounded justify-content-center mt-3 mb-4 pb-1"
                    />
                  </>
              }

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CustomFabric);
