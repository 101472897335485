import React, { useState, useEffect } from "react";
import { HTTP_STATUS } from "../../constants";
import { productApi, cateApi } from "../../apis";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Alert
} from "reactstrap";
import Select from "react-select";
import { convertImagePath } from "../../helpers/utils";
import Dropzone from "react-dropzone";
import * as yup from "yup";
import { useFormik } from "formik";
import { getData } from '../../helpers/localStorage';
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
const EcommerceAddProduct = () => {
  const token = getData("authUser");
  //meta title
  document.title = "Add Product | Sprezzy - React Admin & Dashboard Template";
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;

  const [success, setSuccess] = useState(null);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [imageDefault, setImageDefault] = useState(state?.item?.photos || []);
  const [cateList, setCateList] = useState([]);
  
  const resetLocationState = () => {
    navigate('/ecommerce-products', { state: {
      cate: state?.item?.categories?.[0]
    } });
  };

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  
  const handleSetValuesToNull = () => {
    formik.setValues({
      productName: '',
      price: 0,
      category: '',
      productDesc: '',
      productStock: 0
    });
  };

  //Basic Information
  const formik = useFormik({
    initialValues: {
      productName: state?.item?.name || '',
      price: state?.item?.price || 0,
      category: state?.item?.categories?.[0] ? { value: state?.item?.categories?.[0]?.id, label: state?.item?.categories?.[0]?.name } : '',
      productDesc: state?.item?.description || '',
      productStock: state?.item?.stock || 0
    },
    validationSchema: yup.object().shape({
      productName: yup.string().required('Please Enter Your Product Name'),
      price: yup.number().required('Please Enter Your Price'),
      productStock: yup.number().min(0, 'Product Stock must be greater than or equal to 0').required('Please Enter Your Product Stock')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const payload = {
        name: values.productName,
        price: values.price,
        visible: true,
        description: values.productDesc,
        stock: values.productStock,
      };
      if(state?.item) {
        try {
          const { data, status } = await productApi.patchProduct(state?.item?.id, JSON.stringify(payload));
          if(status === HTTP_STATUS.OK) {
            setSuccess(true);    
            if(values?.category && values?.category !== '') {
              await cateApi.deleteProductCate(state?.item?.categories?.[0]?.id, state?.item?.id);
              await cateApi.postProductCate(values?.category?.value, JSON.stringify({ productId: state?.item?.id }));
            }
            if(selectedFiles?.length > 0 && imageDefault?.length === 0) {
              for (let i = 0; i < state?.item?.photos; i++) {
                await productApi.deleteProductImage(state?.item?.id, state?.item?.photos?.[index]);
              }
              const formData = new FormData();
              for (let i = 0; i < selectedFiles.length; i++) {
                formData.append('file', selectedFiles[i]);
              };
              const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/products/${data?.id}/photos`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${token}`, // Add the token here
                },
              });
              if(response.status === HTTP_STATUS.CREATED) {
                setselectedFiles([]);
              }
            }
            handleSetValuesToNull();
            resetLocationState();
          }
        } catch (error) {
          setSuccess(false);
          console.log('error create product: ', error);
        }
      } else {
        try {
          const { data, status } = await productApi.postProduct(JSON.stringify(payload));
          if(status === HTTP_STATUS.CREATED) {
            setSuccess(true);    
            if(values?.category && values?.category !== '') {
              await cateApi.postProductCate(values?.category?.value, JSON.stringify({ productId: data?.id }));
            }
            if(selectedFiles?.length > 0) {
              const formData = new FormData();
              for (let i = 0; i < selectedFiles.length; i++) {
                formData.append('file', selectedFiles[i]);
              };
              const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/products/${data?.id}/photos`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${token}`, // Add the token here
                },
              });
              if(response.status === HTTP_STATUS.CREATED) {
                setselectedFiles([]);
              }
            }
            handleSetValuesToNull();
          }
        } catch (error) {
          setSuccess(false);
          console.log('error create product: ', error);
        }
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    async function fetchCates() {
      try {
        const { data, status } = await cateApi.getCate();        
        if (status === HTTP_STATUS.OK) {
          const items = data.map(item => {
            return {
              value: item.id,
              label: item.name
            }
          })
          setCateList(items);
        }
      } catch (error) {
        console.log('get attribute error: ', error);
      }
    }
    fetchCates();
  }, []);

  const handleCategoryChange = (selectedOption) => {
    formik.setFieldValue('category', selectedOption);
  };

  useEffect(() => {
    if(success) {
      setTimeout(() => {
        setSuccess(null);
      }, 3000); 
    }
  }, [success]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Alert color="success" isOpen={success !== null}>
          {!state?.item && success ? "Product has been created successfully!" : "Failed to create product!"}
          {state?.item && success ? "Product has been updated successfully!" : "Failed to update product!"}
        </Alert>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="" breadcrumbItem={
            state && state?.item ? 'Edit Product' : 'Add Product'
          } />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Basic Information</CardTitle>
                  <p className="card-title-desc mb-4">
                    Fill all information below
                  </p>

                  <Form onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                      return false;
                    }} autoComplete="off">
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productName">Product Name</Label>
                          <Input
                            id="productName"
                            name="productName"
                            type="text"
                            placeholder="Product Name"
                            value={formik.values.productName}
                            onChange={formik.handleChange}
                            invalid={
                              formik.touched.productName && formik.errors.productName ? true : false
                            }
                          />
                          {formik.errors.productName && formik.touched.productName ? (
                            <FormFeedback type="invalid">{formik.errors.productName}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="price">Price</Label>
                          <Input
                            id="price"
                            name="price"
                            type="number"
                            placeholder="Price"
                            value={formik.values.price}
                            onChange={formik.handleChange}
                            invalid={
                              formik.touched.price && formik.errors.price ? true : false
                            }
                          />
                          {formik.errors.price && formik.touched.price ? (
                            <FormFeedback type="invalid">{formik.errors.price}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productName">Product Stock</Label>
                          <Input
                            id="productStock"
                            name="productStock"
                            type="number"
                            placeholder="Product Stock"
                            value={formik.values.productStock}
                            onChange={formik.handleChange}
                            invalid={
                              formik.touched.productStock && formik.errors.productStock ? true : false
                            }
                            min="0"
                          />
                          {formik.errors.productStock && formik.touched.productStock ? (
                            <FormFeedback type="invalid">{formik.errors.productStock}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="control-label">Category</Label>
                          <Select 
                            name="category" 
                            options={cateList} 
                            className="select2"
                            onChange={handleCategoryChange} 
                            defaultValue={formik.values.category}
                            value={formik.values.category}
                          />
                          {formik.errors.category && formik.touched.category ? (
                            <span className="text-danger">{formik.errors.category}</span>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productDesc">
                            Product Description
                          </Label>
                          <Input
                            tag="textarea"
                            className="mb-3"
                            id="productDesc"
                            name="productDesc"
                            rows={5}
                            placeholder="Product Description"
                            value={formik.values.productDesc}
                            onChange={formik.handleChange}
                            invalid={
                              formik.touched.productDesc && formik.errors.productDesc ? true : false
                            }
                          />
                          {formik.errors.productDesc && formik.touched.productDesc ? (
                            <FormFeedback type="invalid">{formik.errors.productDesc}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary"> Save Changes  </Button>
                      <Button type="button" color="secondary" onClick={() => formik.resetForm()}> Cancel</Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Product Images</CardTitle>
                  <Form>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <ul className="list-unstyled mb-0" id="file-previews">
                      {((imageDefault?.length > 0 ? imageDefault : selectedFiles) || [])?.map((file, index) => {                        
                        return (
                          <li className="mt-2 dz-image-preview" key=''>
                            <div className="border rounded">
                              <div className="d-flex flex-wrap gap-2 p-2">
                                <div className="flex-shrink-0 me-3">
                                  <div className="avatar-sm bg-light rounded p-2">
                                    <img data-dz-thumbnail="" className="img-fluid rounded d-block"
                                     src={imageDefault?.length > 0 ? convertImagePath(file?.path) : file.preview}
                                     alt={imageDefault?.length > 0 ? '' : file.name} />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <div className="pt-1">
                                    <h5 className="fs-md mb-1" data-dz-name>{file.path}</h5>
                                    <strong className="error text-danger" data-dz-errormessage></strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                    <div style={{
                      width: '100%',
                      paddingTop: '20px',
                      paddingRight: '10px',
                    }}>
                      <Button variant="danger" size="sm"
                        onClick={() => {
                          setselectedFiles([]);
                          setImageDefault([]);
                        }}>
                        Delete</Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct;
