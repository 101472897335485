import React, { useEffect , useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Card, CardBody, CardTitle, Col, Container, FormGroup, Form, Input, Label, Nav, NavItem, NavLink, Row } from "reactstrap";
import classnames from "classnames";
import { cloneDeep, isEmpty } from "lodash";
import { HTTP_STATUS } from "../../../../../constants";
import { fabricApi, attributeConfig } from "../../../../../apis";
import { convertImagePath } from '../../../../../helpers/utils';
import { useParams  } from "react-router-dom";
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import Paginations from "components/Common/Pagination";
import Spinners from "components/Common/Spinner";

const AttributeType = () => {

  //meta title
  document.title = "Products | Sprezzy - React Admin & Dashboard Template";

  const navigate = useNavigate();
  const location = useLocation();
  const type = location.pathname.split("/attribute-types/").pop();
  const { id } = useParams();

  const [productsPerPage, setProductPerPage] = useState([]);
  const [cateList, setCateList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [selectedCate, setSelectedCate] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  
  const [parentAttributeConfig, setParentAttributeConfig] = useState({});
  const [newConfigForDependencysDefault, setNewConfigForDependencysDefault] = useState([]);
  const [childAttributeConfigs, setChildAttributeConfigs] = useState([]);
  const [newConfigForDependencys, setNewConfigForDependencys] = useState([]);
  const [attributePartImages, setAttributePartImages] = useState([]);
  const [attributePartImagesFilter, setAttributePartImagesFilter] = useState([]);
  const [listFilter, setListFilter] = useState([]);

  async function fetchAttributeConfigId(id) {
    try {
      const { data, status } = await attributeConfig.getAttributeConfigId(id);        
      if (status === HTTP_STATUS.OK) {
        setParentAttributeConfig(data);
        if(data?.childAttributeConfig?.length > 0) {
          setChildAttributeConfigs(data?.childAttributeConfig);
        }
      }
    } catch (error) {
      console.log('get attribute error: ', error);
    }
  };

  async function fetchDataDependentAttributeConfig(id) {
    try {
      const { data, status } = await attributeConfig.getDataDependentAttributeConfig(id);
      if(status === HTTP_STATUS.OK) {
        const values = data?.map(item => item.newConfigForDependency);
        return values;
      }
    } catch (error) {
      console.log('fetchDataDependentAttributeConfig error: ', error);
    }
  };

  async function fetchDataFabricWithAttributeConfig(id) {
    try {
      const { data, status } = await attributeConfig.getDataFabricWithAttributeConfig(id);
      if(status === HTTP_STATUS.OK) {
        const values = data?.attributePartImages?.map(item => {
          return {
            ...item,
            dependencyId: id,
          }
        });
        return values;
      }
    } catch (error) {
      console.log('fetchDataFabricWithAttributeConfig error: ', error);
    }
  }

  useEffect(() => {
    if(id) {
      fetchAttributeConfigId(id);
    }
  }, [id]);
  
  const fetchDataDependentAttributeConfigDefault = () => {
    if(childAttributeConfigs?.length > 0) {
      const fetchData = async () => {
        const arr = childAttributeConfigs.map((item) => {
          return fetchDataDependentAttributeConfig(item?.id);
        });
        
        try {
          const resolvedArr = await Promise.all(arr);
          setNewConfigForDependencys(resolvedArr.flat());
          setNewConfigForDependencysDefault(resolvedArr.flat());
        } catch (error) {
          console.error('Error resolving promises: ', error);
        }
      };

      fetchData();
      
    } else {
      const fetchData = async () => {
        try {
          const resolvedArr = await fetchDataDependentAttributeConfig(id);
          if(resolvedArr?.length > 0) {
            setNewConfigForDependencys(resolvedArr.flat());
            setNewConfigForDependencysDefault(resolvedArr.flat());
          } else {
            const fetchData_ = async () => {
              try {
                const resolvedArr = await fetchDataFabricWithAttributeConfig(id);
                setAttributePartImages(resolvedArr.flat());
                setAttributePartImagesFilter(resolvedArr.flat());
              } catch (error) {
                console.error('Error resolving promises: ', error);
              }
            };
      
            fetchData_();      
          }
        } catch (error) {
          console.error('Error resolving promises: ', error);
        }
      };

      fetchData();
    }
  };

  useEffect(() => {
    fetchDataDependentAttributeConfigDefault();
  }, [childAttributeConfigs?.length]);

  useEffect(() => {
    if(newConfigForDependencys?.length > 0) {
      const fetchData = async () => {
        const arr = newConfigForDependencys.map((item) => {
          return fetchDataFabricWithAttributeConfig(item?.id);
        });
  
        try {
          const resolvedArr = await Promise.all(arr);
          if(selectedCate?.length > 0) {
            const filterData = resolvedArr?.flat()?.filter((item) => {
              return selectedCate.map((cate) => cate.value).includes(item?.fabric?.id);
            });
            setAttributePartImagesFilter(filterData);
          } else {
            setAttributePartImagesFilter(resolvedArr.flat());
          }
          setAttributePartImages(resolvedArr.flat());
        } catch (error) {
          console.error('Error resolving promises: ', error);
        }
      };

      fetchData();
      
    }
  }, [newConfigForDependencys?.length]);

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const perPageData = 30;
  const indexOfLast = currentPage * perPageData;
  const indexOfFirst = indexOfLast - perPageData;

  useEffect(() => {
    if(attributePartImagesFilter) {
      setProductPerPage(attributePartImagesFilter.slice(indexOfFirst, indexOfLast));
    }
  }, [currentPage, attributePartImagesFilter?.length, type]);

  // search
  const handleSearch = (ele) => {
    const query = ele.value.toLowerCase();
    setSearchKey(query);
  }

  useEffect(() => {
    async function fetchCates() {
      try {
        const { data, status } = await fabricApi.getFabric();
        if (status === HTTP_STATUS.OK) {
          setCateList(data || []);
        }
      } catch (error) {
        console.log('get attribute error: ', error);
      }
    }
    fetchCates();
  }, [type]);


  const handleDeleteProduct = async (item) => {
  };

  const handleFilterCate = () => {
    if(selectedCate?.length > 0) {
      const filterData = attributePartImages.filter((item) => {
        return selectedCate.map((cate) => cate.value).includes(item?.fabric?.id);
      });

      setAttributePartImagesFilter(filterData);
    } else {
      setAttributePartImagesFilter(attributePartImages);
    }
  }
  useEffect(() => {
    handleFilterCate();
  }, [selectedCate?.length]);
  
  const handleChangeCheckbox = (item) => {
    const index = listFilter?.findIndex((i) => i.id === item.id);
    let newListFilter = [];
    if(index === -1) {
      newListFilter = [...listFilter, item];
      setListFilter(newListFilter);
    } else {
      newListFilter = listFilter.filter((i) => i.id !== item.id);
      setListFilter(newListFilter);
    };

    if(newListFilter?.length > 0) {
      setNewConfigForDependencys(newListFilter);
    } else {
      fetchDataDependentAttributeConfigDefault();
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Attribute type- " breadcrumbItem="Jacket" />
          <Row>
            <Col lg="3">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Filter</CardTitle>
                    <div>
                      <h5 className="font-size-14 mb-3">{selectedCate?.name || 'Fabric Category'}</h5>
                      {/* Render Cloth Categories */}
                      <ul className="list-unstyled product-list">
                        <Select 
                          name="fabrics"
                          isMulti={true}
                          options={cateList.map((item) => ({ value: item.id, label: item.name }))} 
                          className="fabrics"
                          onChange={(selectedOption) => {
                            setSelectedCate(selectedOption);
                          }} 
                          defaultValue={selectedCate}
                          value={selectedCate}
                        />
                      </ul>
                    </div>
                    {
                      newConfigForDependencysDefault?.length > 0 && (
                        <div>
                          <h5 className="font-size-14 mb-3">
                            {`Filter ${parentAttributeConfig?.name}`}
                          </h5>
                          <ul className="list-unstyled product-list">
                            <Form>
                            {(newConfigForDependencysDefault || []).map((item, i) => (
                              <FormGroup check className="mt-2" key={i}>
                                <Input type="checkbox" value={item.id} onChange={() => {
                                  handleChangeCheckbox(item);
                                }} />
                                <Label check>{item.name} </Label>
                              </FormGroup>
                            ))}
                            </Form>
                          </ul>
                        </div>
                      )
                    }
                </CardBody>
              </Card>
            </Col>

            <Col lg={9}>
              <Row className="mb-3">
                <Col xl={4} sm={6}>
                  <div className="mt-2" style={{ textAlign: 'left' }}>
                    <h5>{`${parentAttributeConfig?.name} - ${parentAttributeConfig?.type}`}</h5>
                  </div>
                </Col>
                <Col lg={8} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Form className="mt-4 mt-sm-0 float-sm-end d-sm-flex align-items-center">
                    <div className="search-box me-2">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control border-0"
                          placeholder="Search..."
                          onChange={(e) => handleSearch(e.target)}
                          value={searchKey}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                    <Nav className="product-view-nav justify-content-end mt-3 mt-sm-0" pills>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggleTab("1");
                          }}
                        >
                          <i className="bx bx-grid-alt" />
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggleTab("2");
                          }}
                        >
                          <i className="bx bx-list-ul" />
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Form>
                </Col>
              </Row>
              {
                isLoading ? <Spinners setLoading={setLoading} />
                  :
                  <>
                    <Row>
                      {!isEmpty(productsPerPage) &&
                        (productsPerPage || [])?.map((product, key) => {
                          return (
                            <Col xl={4} sm={6} key={"_col_" + key}>
                              <Card>
                                <CardBody>
                                  <div className="product-img position-relative">
                                    <Link
                                      to={"/"}
                                      className="text-dark"
                                      >
                                      <img
                                        style={{ height: "auto"  }}
                                        src={convertImagePath(product?.partImage)}
                                        alt=""
                                        className="img-fluid mx-auto d-block"
                                      />
                                    </Link>
                                  </div>
                                  <div className="mt-4 text-center">
                                    <h5 className="mb-2 text-truncate">
                                      {product?.fabric?.name}
                                    </h5>
                                    <h5 className="mb-1 text-truncate" style={{ fontSize: '12px' }}>
                                      {product.type}
                                    </h5>
                                    <p className="mb-1 text-truncate" style={{ fontSize: '12px' }}>
                                      {product.description}
                                    </p>
                                    {
                                      product.price > 0 && (
                                        <h5 className="my-0">
                                          <b>${product.price}</b>
                                        </h5>
                                      )
                                    }
                                    <div style={{
                                      marginTop: '10px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      gap: '10px',
                                      cursor: 'pointer'
                                    }}>
                                      <i className="fas fa-trash" onClick={
                                        () => {
                                          if (window.confirm("Do you really want to delete product?")) {
                                            handleDeleteProduct(product)
                                          }
                                        }
                                      }></i>
                                      <i className="fas fa-edit" onClick={() => {
                                        navigate(`/ecommerce-add-product`, {
                                          state: { item: product }
                                        })
                                      }}></i>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          )
                        })}
                    </Row>
                    <Paginations
                      perPageData={perPageData}
                      data={attributePartImagesFilter.filter((item, index, self) =>
                        index === self.findIndex((t) => t.fabric.id === item.fabric.id)
                      )}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      isShowingPageLength={false}
                      paginationDiv="col-lg-12"
                      paginationClass="pagination pagination-rounded justify-content-center mt-3 mb-4 pb-1"
                    />
                  </>
              }

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AttributeType);
