import * as rest from "./base";

const getAttributeConfig = () => rest.get("/attributes-config");
const getAttributeConfigId = (id) => rest.get(`/attributes-config/${id}`);
const getAttributeDependency = () => rest.get("/attribute-dependency");
const getDataDependentAttributeConfig = (id) => rest.get(`/attribute-part-image/data-dependent-attribute-config?attributeConfigId=${id}`);
const getDataFabricWithAttributeConfig = (id) => rest.get(`/attribute-part-image/data-fabric-with-attribute-config?attributeConfigId=${id}`);

export default {
  getAttributeConfig,
  getAttributeDependency,
  getAttributeConfigId,
  getDataDependentAttributeConfig,
  getDataFabricWithAttributeConfig
};
