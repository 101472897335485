import * as rest from "./base";

const getFabricType = () => rest.get("/fabric-type");
const getFabric = () => rest.get("/fabric");
const postFabricTypeFabric = (payload) => rest.post("/fabric-type", payload);
const getFabricTypeFabrics = (id) => rest.get(`/fabric-type/${id}`);
const postFabricTypeFabricsFabricId = (id, payload) => rest.post(`/fabric/${id}/fabric-type`, payload);
const getFabricFabricType = (id) => rest.get(`/fabric/${id}/fabric-type`);
const deleteFabricTypeFabricsFabricId = (id, fabricTypeId) => rest.del(`/fabric/${id}/fabric-type/${fabricTypeId}`);

export default {
  getFabricType,
  getFabric,
  getFabricTypeFabrics,
  postFabricTypeFabric,
  postFabricTypeFabricsFabricId,
  getFabricFabricType,
  deleteFabricTypeFabricsFabricId
};
